import { Grid, Link } from "@material-ui/core";
import React from "react";

const aboutText = (
  <>
    <h2>Hello. I am Gareth.</h2>
    <text>
      In 2020, after a 15 year career in finance I decided to take a break and
      learn how to code. I created this website to share my journey. {<p />}
      Initially I took a software development course at Northcoders in
      Manchester. Having really enjoyed the course and gained confidence in my
      ability to skills, upon graduation I decided to keep learning. {<p />}I
      chose to focus on game programming in Unity as this afforded me the
      opportunity to be creative and develop tangible end projects as well as
      learn C#. {<p />}
      After completing online courses in 2D and 3D programming I completed my
      first Games Jam and released a working demo of a 3D squash simulator.{" "}
      {<p />} I have now returned to working in finance and more recently
      acquired 3D printer after years of interest and have been learning to
      design and print models{<p />}
      {<p />}Please check out my projects page and blog for my latest projects &
      see the links below for further info and contacts. {<p />}Thank you for
      visiting!{<p />}
    </text>
  </>
);

export { aboutText };
