const projectText = [
  {
    title: "Squash Island",
    description: `My current project under development is a 3D physics based squash game. A working demo has been released to itch.io`,
    altDescription:
      "I chose squash as the subject for a Unity 3D learning project as I love the game, it has interesting physics and AI shot choice to model and limited artistic requirements! The artwork and code were all created from scratch.",
    imgSrc: require("../../../src/resources/projectCards/SquashCover.jpg"),
    imgTitle: "Squash Game Front Screen",
    altImgSrc: require("../../../src/resources/projectCards/SquashScreenShot.jpg"),
    altImgTitle: "Screenshot of squash court",
    gitHubFront: "",
    gitHubBack: "",
    frontURL: "https://higashimori.itch.io/squash-island",
    backURL: "",
    presentation: "",
  },

  {
    title: "One Day",
    description: `My first original game was a Unity 2D Game built for GJL - Games Parade GameJam under the theme of Carpe Diem`,
    altDescription:
      "The game jam lasted one week - all the artwork and code were created entirely from scratch for the jam.",
    imgSrc: require("../../../src/resources/projectCards/oneDayCover.png"),
    imgTitle: "Game Front Screen",
    altImgSrc: require("../../../src/resources/projectCards/oneDayScreenShot.png"),
    altImgTitle: "Screenshot of shopping list",
    gitHubFront: "",
    gitHubBack: "",
    frontURL: "https://higashimori.itch.io/one-day",
    backURL: "",
    presentation: "",
  },

  {
    title: "Aisle Navigate",
    description: `A smart shopping assistant that categorises your 
groceries and provides an efficient route through any supermarket using crowd sourced data.`,
    altDescription:
      "The project was completed in two weeks. It is a Progressive Web App using React front-end and MongoDB/Monggose back-end",
    imgSrc: require("../../../src/resources/projectCards/aisleNavigate.png"),
    imgTitle: "Screenshot of supermarket path",
    altImgSrc: require("../../../src/resources/projectCards/aisleNavigateAlt.png"),
    altImgTitle: "Screenshot of shopping list",
    gitHubFront: "https://github.com/higashiM/project-sweep-fe",
    gitHubBack: "https://github.com/higashiM/project-sweep-be",
    frontURL: "https://project-sweep-fe.web.app/",
    backURL: "https://aisleonator.herokuapp.com/api/",
    presentation: "https://www.youtube.com/watch?v=mo6wSLX5xlw",
  },

  {
    title: "Fake-Fake-News",
    description: `An app for authors post articles with pictures and allows other users to add comments and vote on articles and comments`,
    altDescription:
      "The project uses a React front-end coupled with a NodeJS API which includes a GraphQL endpoint",
    imgSrc: require("../../../src/resources/projectCards/fake-fake-news.png"),
    imgTitle: "Screenshot of fake-fake-news app",
    altImgSrc: require("../../../src/resources/projectCards/fake-fake-news2.png"),
    altImgTitle: "Screenshot of fake fake news app",
    gitHubFront: "https://github.com/higashiM/fake-fake-news",
    gitHubBack: "https://github.com/higashiM/nc-news",
    frontURL: "https://fake-fake-news.netlify.app/",
    backURL: "https://red-octopus.herokuapp.com/api",
    presentation: "",
  },
];

export { projectText };
