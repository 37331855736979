//import "./App.css";
import React, { useState } from "react";
import { Grid, CssBaseline, Switch } from "@material-ui/core";
import TabPanel from "./components/TabPanel";
import Footer from "./components/Footer";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  orange,
  lightBlue,
  deepPurple,
  deepOrange,
  yellow,
} from "@material-ui/core/colors";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const palleteType = darkMode ? "dark" : "light";
  const mainPrimaryColor = darkMode ? deepOrange[500] : lightBlue[500];
  const mainSecondaryColor = darkMode ? yellow[500] : orange[500];

  const theme = createMuiTheme({
    palette: {
      type: palleteType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
    },
  });
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      padding: "10px 10px",
    },
  }));

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container spacing={0} className={classes.container} wrap={"nowrap"}>
        <Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
        <Grid item>{TabPanel()}</Grid>
        <Grid item>{Footer()}</Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
